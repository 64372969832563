import styled from 'styled-components/macro';
import media from '../modules/media';

export const Wrapper = styled.div`
  &:nth-child(3) {
    margin-bottom: 40px;
  }
`;

export const Chart = styled.div`
  width: 50%;

  ${media.desktop`width: 70%;`}
  ${media.tablet`width: 80%;`}
  ${media.phone`width: 100%;`}
`;

export const CreditCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const Label = styled.span`
  white-space: nowrap;
  margin-right: 10px;
`;

export const Plan = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;

  > a {
    margin-left: 20px;
  }
`;

export const Warning = styled.span`
  color: red;
`;
