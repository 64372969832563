import styled from 'styled-components/macro';
import media from '../modules/media';

export const Wrapper = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin-top: 20px;

  > a, > button {
    margin: 10px 20px 0 0;

    ${media.phone`width: 100%;`};
  }

  > a button {
    ${media.phone`width: 100%;`};
  }

  > input {
    max-width: 340px;
  }
`;

export const Label = styled.span`
  white-space: nowrap;
  margin-right: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  > button {
    margin-right: 10px;
  }
`;
