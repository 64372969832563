import React from 'react';
import Layout from '../components/Layout';
import Billing from '../components/Billing';
import Spreadsheets from '../components/Spreadsheets';

const DashboardPage = () => (
  <Layout>
    <Spreadsheets />
    <Billing />
  </Layout>
);

export default DashboardPage;
