import React, {useContext, useState} from 'react';
import {navigate, Link} from 'gatsby';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {atomOneDark} from 'react-syntax-highlighter/dist/styles/hljs';
// import ReactTooltip from 'react-tooltip';
import UserContext from '../modules/UserContext';
import Input from './Input';
import Button from './Button';
import Card from './Card';
import Modal from './Modal';
import SectionHeader from './SectionHeader';
import {Wrapper, Content, Row, Label, ButtonWrapper} from './Spreadsheets.styles';

const BEurl = process.env.BACKEND;

const getCode = (spreadsheetId, token, language) => {
  const code = {
    javascript: `
const queryData = async () => {
  const spreadsheetId = '${spreadsheetId}';
  const response = await fetch(\`${BEurl}/api/\${spreadsheetId}\`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': '${token}'
    },
    body: JSON.stringify({
      query: \`
        {
          get (limit: 20) {
            YOUR_API_FIELDS
          }
        }
      \`
    })
  });
  const responseJSON = await response.json();
  console.log(responseJSON);
};

queryData();
`,
    bash: `
curl ${BEurl}/api/${spreadsheetId} \\
-X POST \\
-H "Content-Type: application/json" \\
-H "token: ${token}" \\
--data '{ "query": "{ get (limit: 20) { YOUR_API_FIELDS } }" }'
`,
    php: `
$query = <<<'JSON'
query{
    get(limit: 20) {
      YOUR_API_FIELDS
    }
}
JSON;

$json = json_encode(['query' => $query]);

$url = "${BEurl}/api/${spreadsheetId}";

$chObj = curl_init();
curl_setopt($chObj, CURLOPT_URL, $url);
curl_setopt($chObj, CURLOPT_RETURNTRANSFER, true);
curl_setopt($chObj, CURLOPT_CUSTOMREQUEST, 'POST');
curl_setopt($chObj, CURLOPT_HEADER, true);
curl_setopt($chObj, CURLOPT_VERBOSE, true);
curl_setopt($chObj, CURLOPT_POSTFIELDS, $json);
curl_setopt($chObj, CURLOPT_HTTPHEADER,
  array(
    'User-Agent: PHP Script',
    'Content-Type: application/json;charset=utf-8',
    'token: bb650d7a8910de05ed3b73dedbe66b8f7467843f'
  )
);

$response = curl_exec($chObj);
echo $response;
`,
    python: `
import requests

headers = {
  'Content-Type': 'application/json',
  'token': '${token}',
}

data = '{ "query": "{ get (limit: 20) { YOUR_API_FIELDS } }" }'

response = requests.post('${BEurl}/api/${spreadsheetId}', headers=headers, data=data)
`
  };

  return code[language];
};

const tooltips = {
  apiUrl: 'Open GraphiQL',
  spreadsheetUrl: 'Open the spreadsheet on Google Drive docs',
  recreate: 'Recreate the GraphQL schema of the API if you made changes to the first row of the spreadsheet',
  accessToken: 'You have to send this token with your requests to access the API. Check the example code.'
};

const Spreadsheets = () => {
  const user = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState(false);
  const [language, setLanguage] = useState('bash');

  const handleExampleCode = spreadsheet => {
    setSelectedSpreadsheet(spreadsheet);
    setIsModalOpen(!isModalOpen);
  };

  const handleRecreate = spreadsheet => {
    navigate(`/create?spreadsheetId=${spreadsheet.spreadsheetId}`);
  };

  return (
    <Wrapper>
      <SectionHeader>
        APIs &nbsp; &nbsp; &nbsp;
        <Link to="/create">
          <Button>Create new</Button>
        </Link>
      </SectionHeader>
      {user.spreadsheets.map(spreadsheet => (
        <Card key={spreadsheet.spreadsheetId} title={spreadsheet.title}>
          <Content>
            <Row>
              <a href={`${BEurl}/api/${spreadsheet.spreadsheetId}?token=${spreadsheet.token}`} target="blank">
                <Button help={tooltips.apiUrl}>API URL</Button>
              </a>
              <a href={`https://docs.google.com/spreadsheets/d/${spreadsheet.spreadsheetId}/edit`} target="blank">
                <Button help={tooltips.spreadsheetUrl}>Open Spreadsheet</Button>
              </a>
              <Button onClick={() => handleExampleCode(spreadsheet)}>Example code</Button>
              <Button help={tooltips.recreate} onClick={() => handleRecreate(spreadsheet)}>
                Recreate
              </Button>
            </Row>
            <Row>
              <Label data-tip={tooltips.accessToken}>Access Token:</Label>
              <Input value={spreadsheet.token} readOnly />
            </Row>
          </Content>
        </Card>
      ))}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Card title="Example Code">
          <span>Note: Replace YOUR_API_FIELDS with the fields of your API</span>
          <ButtonWrapper>
            <Button onClick={() => setLanguage('bash')}>cURL</Button>
            <Button onClick={() => setLanguage('javascript')}>JavaScript</Button>
            <Button onClick={() => setLanguage('php')}>PHP</Button>
            <Button onClick={() => setLanguage('python')}>Python</Button>
          </ButtonWrapper>
          <SyntaxHighlighter language={language} style={atomOneDark}>
            {getCode(selectedSpreadsheet.spreadsheetId, selectedSpreadsheet.token, language)}
          </SyntaxHighlighter>
        </Card>
      </Modal>
    </Wrapper>
  );
};

export default Spreadsheets;
