import React, {useContext, useEffect} from 'react';
import {Link} from 'gatsby';
import {Line} from 'react-chartjs-2';
import {toast} from 'react-toastify';
import UserContext from '../modules/UserContext';
import {blue, gray, pink} from '../modules/colors';
import Card from './Card';
import Button from './Button';
import SectionHeader from './SectionHeader';
import {Wrapper, Chart, CreditCard, Label, Plan, Warning} from './Billing.styles';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const getPrevMonth = (year, month) => {
  let prevYear = year;
  let prevMonth = month;

  if (prevMonth === 1) {
    prevYear--;
    prevMonth = 12;
  } else {
    prevMonth--;
  }

  return [prevYear, prevMonth];
};

const chartData = {
  labels: [],
  datasets: [
    {
      label: 'Requests',
      backgroundColor: blue,
      borderColor: gray,
      pointBackgroundColor: pink,
      pointBorderColor: pink,
      data: []
    }
  ]
};

const chartOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
};

const Billing = () => {
  const user = useContext(UserContext);

  // collect data for the last 12 months
  const data = [];

  user.requests.forEach((item, index) => {
    const [year, month] = item.date.split('/').map(i => parseInt(i, 10));

    // add the value in the array
    data.push({month, requests: item.counter});

    // find the previous month to check if have data for it
    let [prevYear, prevMonth] = getPrevMonth(year, month);

    // fill in any months with no requests (data)
    while (user.requests[index + 1] && user.requests[index + 1].date !== `${prevYear}/${prevMonth}`) {
      data.push({
        month: prevMonth,
        requests: 0
      });
      [prevYear, prevMonth] = getPrevMonth(prevYear, prevMonth);
    }
  });

  // to make the chart better, if we have data for only 1 month, add the prevMonth with 0 requests
  if (data.length === 1) {
    data.push({
      month: data[0].month - 1 ? data[0].month - 1 : 12,
      requests: 0
    });
  }

  // reset chart data
  chartData.labels = [];
  chartData.datasets[0].data = [];

  // use unshift to reverse them to get in proper chonological order
  data.forEach(item => {
    chartData.labels.unshift(months[item.month - 1]);
    chartData.datasets[0].data.unshift(item.requests);
  });

  const usedRequests = (user.requests && user.requests[0] && user.requests[0].counter) || 0;

  const plans = {
    free: process.env.FREE_REQUESTS,
    plus: process.env.PLUS_REQUESTS,
    pro: process.env.PRO_REQUESTS
  };
  const availableRequests = plans[user.plan];

  useEffect(() => {
    if (usedRequests >= availableRequests) {
      toast.error('You reached the monthly request limit. Please upgrade to a bigger plan or contact us!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000
      });
    }
  }, []);

  return (
    <Wrapper>
      <SectionHeader>Account Info</SectionHeader>
      <Card title="Usage">
        <div>
          Current month: &nbsp;
          {usedRequests}/{availableRequests}
          {usedRequests >= availableRequests && <Warning> BLOCKED</Warning>}
        </div>
        <Chart>
          <Line data={chartData} options={chartOptions} />
        </Chart>
      </Card>
      <Card title="Billing">
        <Plan>
          <div>
            Active plan: &nbsp;
            <strong>{user.plan && user.plan.charAt(0).toUpperCase() + user.plan.slice(1)}</strong>
          </div>
          <Link to="/pricing">
            <Button>Change</Button>
          </Link>
        </Plan>
        {user.cards[0] && <Label>This account is billed to:</Label>}
        {user.cards.map(card => (
          <CreditCard key={`${card.brand}-${card.last4}`}>
            <div>{`${card.brand} card ending in ${card.last4}`}</div>
          </CreditCard>
        ))}
        {user.cards[0] && (
          <Link to="/add-card/">
            <Button>Add new card</Button>
          </Link>
        )}
      </Card>
    </Wrapper>
  );
};

export default Billing;
